import { useContext, useEffect, useState } from "react";
import { NavBar } from "../../components/NavBar";
import { api } from "../../api/api";
import { PiStudentBold } from "react-icons/pi";
import { MdOutlinePayments } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import FileBase64 from '../../components/FileBase64';
import PIX from "react-qr-code";

import './style.css'
import { DateTime } from "luxon";
import { GlobalContext } from "../../providers/GlobalProvider";
import { Toast } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { useSearchParams } from 'react-router-dom';

export function SubscribePage () {
  const navigate = useNavigate()
  const { student } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)
  const [loadingScreen, setLoadingScreen] = useState(true)
  const [isMaiorDeIdade, setIsMaiorDeIdade] = useState(false)

  const [searchParams] = useSearchParams();
  const courseIdParams = searchParams.get('id');

  const [subscription, setSubscription] = useState({
    student: {
      id: '',
      name: '',
      cpf: '',
      rg: '',
      telefone: '',
      email: '',
      endereco: '',
      numero: '',
      bairro: '',
      cidade: '',
      cep: '',
      dataNascimento: '',
      documents: [],
      oldSchool: '',
      isSchoolPublic: 'Escola pública',
      isResideForaVicosa: 'Não',
      isStudentOfOmega: 'Não',
      isOldStudentOfOmega: 'Não'
    },
    courseId: '-1',
    paymentCourse: 'A Vista transferencia',
    paymentMaterial: 'A Vista transferencia',
    documents: [],
    pix: '',
    responsible: {
      name: '',
      cpf: '',
      rg: '',
      telefone: '',
      email: '',
      documents: []
    },
    bondsmans: {
      name: '',
      cpf: '',
      rg: '',
      telefone: '',
      email: '',
      documents: []
    }
  })

  const [courses, setCourses] = useState([])

  async function getCourses () {
    setLoadingScreen(true)
    const response = await api.get('/api/v1/courses')

    setCourses(response.data.data)

    if (courseIdParams) {
      setSubscription({
        ...subscription,
        student: {
          ...subscription.student,
          ...subscription
        },
        courseId: courseIdParams
      })
    }

    setLoadingScreen(false)
  }

  useEffect(() => {
    getCourses()
  }, [])

  useEffect(() => {
    if (student.id && !subscription?.student?.id) {
      setSubscription({
        ...subscription,
        student: {
          ...student,
          id: student.id,
          name: student.name,
          cpf: student.cpf,
          rg: student.rg,
          telefone: student.phone,
          email: student.email,
          endereco: student.address,
          numero: student.number,
          bairro: student.district,
          cidade: student.city,
          cep: student.cep,
          dataNascimento: student.birthDate,
          documents: [],
          oldSchool: '',
          isSchoolPublic: student.isSchoolPublic === true ? 'Escola pública' : 'Escola particular',
          isResideForaVicosa: student.isResideForaVicosa === true ? 'Sim' : 'Não',
          isStudentOfOmega: student.isStudentOfOmega === true ? 'Sim' : 'Não',
          isOldStudentOfOmega: student.isOldStudentOfOmega === true ? 'Sim' : 'Não',
        }
      })

      if (DateTime.fromSQL(student?.birthDate).diff(DateTime.local(), 'year').years > -18) {
        setIsMaiorDeIdade(true)
      }
    }
  }, [student.id])

  async function save () {
    setLoading(true)

    if (!student.id || !subscription.courseId || subscription.courseId === '-1') {
      setLoading(false)
      return Toast.warning('Todos campos são obrigatórios', 'Validações')
    }

    if (subscription.paymentCourse === 'A Prazo' && subscription.bondsmans.documents.length < 4) {
      setLoading(false)
      return Toast.warning('Deve se preencher as informações do fiador em pagamento (Á prazo)', 'Validações')
    }

    if (subscription?.bondsmans?.name && subscription.bondsmans.documents.length < 4) {
      setLoading(false)
      return Toast.warning('Todos anexos de documentos são obrigatórios, no cadastro do fiador', 'Validações')
    }

    if (subscription.responsible.name && subscription.responsible.documents.length < 4) {
      setLoading(false)
      return Toast.warning('Todos anexos de documentos são obrigatórios, no responsável financeiro', 'Validações')
    }

    Toast.success('Estamos realizando a matricula aguarde um momento')

    await api.post('/api/v1/student/course', {
      studentId: student.id,
      courseId: subscription.courseId,
      responsible: subscription?.responsible?.name ? subscription.responsible : null,
      bondsmans: subscription.bondsmans?.name ? subscription.bondsmans : null,
      paymentCourse: subscription.paymentCourse,
      paymentMaterial: subscription.paymentMaterial,
      pix: subscription.pix
    }).finally(() => setLoading(false))

    Toast.success('Matrícula realizada com sucesso')

    setLoading(false)

    setTimeout(() => {
      navigate('/matricula')
    }, 1000);
  }

  return (
    <>
      <NavBar>
        <div id="page-home">
          <div className="container">
            <h1 className="text-center bg-primary rounded text-white">
              <PiStudentBold size={40} /> Realizar matricula
            </h1>
            <br />

            {loadingScreen && (
              <div className="w-100" style={{ margin: '0 auto', textAlign: 'center' }}>
                <h3>Carregando informações...</h3>
                <div className="spinner-border" role="status">
                </div>
              </div>
            )}

            {loadingScreen === false && (
              <div className="row">
                <div className="col-sm-12 mt-4">
                  <h3 className="text-center">Selecione o curso que deseja se matricular: </h3>
                </div>

                <div className="col-sm-12 mt-4 row border rounded pt-3 pb-3">
                  <div className="col-sm-4" style={{ margin: '0 auto' }}>
                    <select
                      className="form-control form-control-sm text-center"
                      value={subscription.courseId}
                      onChange={(e) => setSubscription({ ...subscription, courseId: e.target.value })}
                    >
                      <option key='-1' value='-1'>Selecione</option>
                      {courses.map((course) => {
                        return (
                          <option key={course.id} value={course.id}>{course.name}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-12 mt-4">
                  <h3 className="text-center"><MdOutlinePayments size={30} /> Selecione a forma de pagamento do curso:</h3>
                </div>

                <div className="col-sm-12 mt-4 row border rounded pt-3 pb-3">
                  <div className="col-sm-4" style={{ margin: '0 auto' }}>
                    <select value={subscription.paymentCourse} onChange={(e) => setSubscription({ ...subscription, paymentCourse: e.target.value })} className="form-control form-control-sm" aria-label="Default select example">
                      <option value="A Vista transferencia">A Vista transferencia</option>
                      <option value="PIX">PIX</option>
                      <option value="Déposito">Déposito</option>
                      <option value="A Prazo">Á prazo</option>
                    </select>
                    <span className="text-danger" style={{ fontSize: 12 }}>*Pagamento á prazo requer dados do fiador.</span>
                  </div>
                </div>

                <div className="col-sm-12 mt-4">
                  <h3 className="text-center"><MdOutlinePayments size={30} /> Selecione a forma de pagamento do material:</h3>
                </div>

                <div className="col-sm-12 mt-4 row border rounded pt-3 pb-3">
                  <div className="col-sm-4" style={{ margin: '0 auto' }}>
                    <select value={subscription.paymentMaterial} onChange={(e) => setSubscription({ ...subscription, paymentMaterial: e.target.value })} className="form-control form-control-sm" aria-label="Default select example">
                      <option value="A Vista transferencia">A Vista transferencia</option>
                      <option value="PIX">PIX</option>
                      <option value="Déposito">Déposito</option>
                      <option value="A Prazo">Á prazo</option>
                    </select>
                  </div>
                </div>

                {(subscription.paymentCourse === 'A Prazo' || subscription.paymentMaterial === 'A Prazo') && (
                  <>

                    <div className="col-sm-12 mt-4">
                      <h3 className="text-center"><IoMdPerson size={30} className="mb-2" /> Informações do fiador:</h3>
                    </div>
                    <div className="col-sm-12 row">
                      <div className="col-sm-6">
                        <label htmlFor="">Nome</label>
                        <input
                          value={subscription.bondsmans.name}
                          onChange={(e) => setSubscription({ ...subscription, bondsmans: { ...subscription.bondsmans, name: e.target.value } })}
                          type="text"
                          maxLength={255}
                          placeholder="Nome"
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="">E-mail</label>
                        <input
                          value={subscription.bondsmans.email}
                          onChange={(e) => setSubscription({ ...subscription, bondsmans: { ...subscription.bondsmans, email: e.target.value } })}
                          type="email"
                          placeholder="E-mail"
                          maxLength={100}
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Telefone</label>
                        <ReactInputMask
                          mask="(99) 9 9999-9999"
                          className="form-control form-control-sm"
                          placeholder="Digite seu telefone"
                          value={subscription.bondsmans.telefone}
                          onChange={(e) => setSubscription({
                            ...subscription,
                            bondsmans: {
                              ...subscription.bondsmans,
                              telefone: String(e.target.value || '').replace('(', '').replace(')', '').replace(/ /ig, '').replace('-', '')
                            }
                          })}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">CPF</label>
                        <ReactInputMask
                          mask="999.999.999-99"
                          className="form-control form-control-sm"
                          value={subscription.bondsmans.cpf}
                          onChange={(e) => setSubscription({
                            ...subscription,
                            bondsmans: {
                              ...subscription.bondsmans,
                              cpf: String(e.target.value).replace('-', '').replace(/\./ig, '')
                              
                            }
                          })}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">RG</label>
                        <input
                          value={subscription.bondsmans.rg}
                          onChange={(e) => setSubscription({ ...subscription, bondsmans: { ...subscription.bondsmans, rg: e.target.value } })}
                          type="text"
                          placeholder="Nome"
                          maxLength={25}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-4">
                      <h3 className="text-center">Anexos de documentos</h3>
                    </div>

                    <div className="col-sm-12 mt-4 row border rounded pt-3 pb-3">
                      <div className="col-sm-4 mb-3">
                        <label htmlFor="formFile" className="form-label">RG (Frente)</label>
                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              bondsmans: {
                                ...subscription.bondsmans,
                                documents: [
                                  ...subscription.bondsmans.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'rgFiador'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label htmlFor="formFile" className="form-label">RG (Verso)</label>
                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              bondsmans: {
                                ...subscription.bondsmans,
                                documents: [
                                  ...subscription.bondsmans.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'rgFiador-verso'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-4 mb-3">
                        <label htmlFor="formFile" className="form-label">CPF</label>
                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              bondsmans: {
                                ...subscription.bondsmans,
                                documents: [
                                  ...subscription.bondsmans.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'cpfFiador'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-6 mb-3">
                        <label htmlFor="formFile" className="form-label">Comprovante Residência</label>
                        <br />
                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              bondsmans: {
                                ...subscription.bondsmans,
                                documents: [
                                  ...subscription.bondsmans.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'comproResiFiador'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-12">
                        <br />
                        <span className="text-danger" style={{ fontSize: 12 }}>*Todos os campos devem ser preenchidos e todos arquivos devem ser anexados.</span>
                      </div>
                    </div>
                  </>
                )}

                {isMaiorDeIdade && (
                  <>
                    <div className="col-sm-12 mt-4">
                      <h3 className="text-center"><IoMdPerson size={30} className="mb-2" /> Informações do responsável financeiro:</h3>
                    </div>
                    <div className="col-sm-12 row">
                      <div className="col-sm-6">
                        <label htmlFor="">Nome</label>
                        <input
                          value={subscription.responsible.name}
                          onChange={(e) => setSubscription({ ...subscription, responsible: { ...subscription.responsible, name: e.target.value } })}
                          type="text"
                          maxLength={255}
                          placeholder="Nome"
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="">E-mail</label>
                        <input
                          value={subscription.responsible.email}
                          onChange={(e) => setSubscription({ ...subscription, responsible: { ...subscription.responsible, email: e.target.value } })}
                          type="email"
                          maxLength={100}
                          placeholder="E-mail"
                          className="form-control form-control-sm"
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">Telefone</label>
                        <ReactInputMask
                          mask="(99) 9 9999-9999"
                          className="form-control form-control-sm"
                          placeholder="Digite seu telefone"
                          value={subscription.responsible.telefone}
                          onChange={(e) => setSubscription({
                            ...subscription,
                            responsible: {
                              ...subscription.responsible,
                              telefone: String(e.target.value || '').replace('(', '').replace(')', '').replace(/ /ig, '').replace('-', '')
                            }
                          })}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">CPF</label>
                        <ReactInputMask
                          mask="999.999.999-99"
                          className="form-control form-control-sm"
                          value={subscription.responsible.cpf}
                          onChange={(e) => setSubscription({
                            ...subscription,
                            responsible: {
                              ...subscription.responsible,
                              cpf: String(e.target.value).replace('-', '').replace(/\./ig, '')
                            }
                          })}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="">RG</label>
                        <input
                          value={subscription.responsible.rg}
                          onChange={(e) => setSubscription({ ...subscription, responsible: { ...subscription.responsible, rg: e.target.value } })}
                          type="text"
                          maxLength={25}
                          placeholder="Nome"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-4">
                      <h3 className="text-center">Anexos de documentos</h3>
                    </div>

                    <div className="col-sm-12 mt-4 row border rounded pt-3 pb-3">
                      <div className="col-sm-6 mb-3">
                        <label htmlFor="formFile" className="form-label">RG (Frente)</label>
                        <br />
                        <FileBase64
                          multiple={false}

                          onDone={function (e) {
                            console.log(e)
                            setSubscription({
                              ...subscription,
                              responsible: {
                                ...subscription.responsible,
                                documents: [
                                  ...subscription.responsible.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'rgresp'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-6 mb-3">
                        <label htmlFor="formFile" className="form-label">RG (Verso)</label>
                        <br />

                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              responsible: {
                                ...subscription.responsible,
                                documents: [
                                  ...subscription.responsible.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'rgresp-verso'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-6 mb-3">
                        <label htmlFor="formFile" className="form-label">CPF</label>
                        <br />

                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              responsible: {
                                ...subscription.responsible,
                                documents: [
                                  ...subscription.responsible.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'cpfresp'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-6 mb-3">
                        <label htmlFor="formFile" className="form-label">Comprovante Residência</label>
                        <br />
                        <FileBase64
                          multiple={false}
                          onDone={function (e) {
                            setSubscription({
                              ...subscription,
                              responsible: {
                                ...subscription.responsible,
                                documents: [
                                  ...subscription.responsible.documents,
                                  {
                                    base64: `${e.base64}`,
                                    name: e.name,
                                    key: 'comproResiResp'
                                  }
                                ]
                              }
                            })
                          }} />
                      </div>
                      <div className="col-sm-12">
                        <span className="text-danger" style={{ fontSize: 12 }}>*Todos os campos devem ser preenchidos e todos arquivos devem ser anexados.</span>
                      </div>
                    </div>
                  </>
                )}


                {subscription.courseId !== '-1' && (
                  <div className="mt-4 col-sm-12">
                    <h3 className="text-center">Pagamento de matrícula via QR Code</h3>
                    <div className="mt-4 text-center">
                      <PIX
                        value={courses?.find(e => e.id === Number(subscription.courseId))?.pix ?? ''}
                      />
                    </div>
                    <hr />
                    <label htmlFor="">Comprovante do PIX da matrícula</label>
                    <br />
                    <FileBase64
                      multiple={false}
                      onDone={function (e) {
                        setSubscription({
                          ...subscription,
                          pix: {
                            base64: `${e.base64}`,
                            name: e.name,
                            key: 'pix'
                          }
                        })
                      }} />
                  </div>
                )}

                <div className="col-sm-12 row">
                  <button className="btn btn-sm btn-success mt-4"
                    onClick={() => save()}>Enviar matricula</button>
                  {loading && (
                    <div className="spinner-border mt-4" style={{ marginLeft: 20 }} role="status">
                    </div>
                  )}
                </div>
              </div>
            )}

          </div>
        </div>
      </NavBar>
    </>
  )
}